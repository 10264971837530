export const newlanguage = {
  id: 'it',
  jezik: 'NewLanguage',
  naslovna01: 'CODE 001 Hail Mary and Jesus!',
  naslovna02: 'CODE 002 Holy Mary Rosary',
  daniTjedan: [
    { value: 'pon', label: 'CODE 003 Mon' },
    { value: 'uto', label: 'CODE 004 Tue' },
    { value: 'sri', label: 'CODE 005 Wen' },
    { value: 'cet', label: 'CODE 006 Thu' },
    { value: 'pet', label: 'CODE 007 Fri' },
    { value: 'sub', label: 'CODE 008 Sta' },
    { value: 'ned', label: 'CODE 009 Sun' },
  ],
  otajstvoSvjetla: 'CODE 010 Light',
  otajstvoZalosno: 'CODE 011 Sorrowful',
  otajstvoSlavno: 'CODE 012 Glorious',
  otajstvoRadosna: 'CODE 013 Joyful',
  uImeOcaSina:
    'CODE 014 In the name of the Father and of the Son  † and of the Holy Spirit.',
  oceNas: 'CODE 015 Our father',
  isuse: 'CODE 016 Jesus',
  oceNasText:
    'CODE 017 , who art in heaven, hallowed be thy name, thy kingdom come, thy will be done on earth as it is in heaven. Give us this day our daily bread and forgive us our trespasses as we forgive those who trespass against us, and lead us not into temptation but deliver us from evil.',
  amen: 'CODE 018 Amen.',
  slavaOcu:
    'CODE 019 Glory be to the Father, and to the Son, † and to the Holy Spirit. As it was in the beginning, is now, and ever shall be, world without end.',
  zdravoMarijaPocetak:
    'CODE 020 Hail, Mary, full of grace, the Lord is with thee: blessed art thou among women, and blessed is the fruit of thy womb,',
  zdravoMarijaKraj:
    'CODE 021 Holy Mary, Mother of God, pray for us sinners, now, and at the hour of our death.',
  oMojIsuse:
    'CODE 022 O my Jesus, forgive us our sins, save us from the fires of hell, lead all souls to heaven especially those who are in most need of Your mercy.',
  zdravoKraljice:
    'CODE 023 Zdravo Kraljice, Majko milosrđa, živote, slasti i ufanje naše, zdravo!\n K tebi vapijemo prognani sinovi Evini. \n K tebi uzdišemo tugujući i plačući u ovoj suznoj dolini. \n Svrni, dakle, Zagovornice naša one svoje milostive oči na nas te nam poslije ovoga progonstva pokaži Isusa, blagoslovljeni plod utrobe svoje. \n O blaga, o mila, o slatka Djevice Marijo.',
  apostolskoUvod:
    'CODE 024 , to je tebi za ljubav, za obraćenje grješnika i kao naknada za uvrede koje se nanose Bezgrešnom Srcu Marijinu.',
  apostolskoVjerovanje: "025 The Apostles' Creed",
  apostolskoVjerovanjeText:
    'CODE 026 Vjerujem u Boga, Oca svemogućega, Stvoritelja neba i zemlje. I u Isusa Krista, Sina njegova jedinoga, Gospodina našega, koji je začet po Duhu Svetom, rođen od Marije Djevice, mučen pod Poncijem Pilatom, raspet, umro i pokopan; sašao nad pakao; treći dan uskrsnuo od mrtvih; uzašao na nebesa, sjedi o desnu Boga Oca svemogućega; odonud će doći suditi žive i mrtve. Vjerujem u Duha Svetoga, svetu Crkvu katoličku, općinstvo svetih, oproštenje grijeha, uskrsnuće tijela, i život vječni.',
  vjera: 'CODE 027, who may increase our faith!',
  ufanje: 'CODE 028, who may increase our houp!',
  ljubav: 'CODE 029, who may increase our love!',
  naslov0: 'CODE 030 The beginning of the rosary - †',
  naslov1: 'CODE 031 1. bead',
  naslov2: 'CODE 032 2. bead',
  naslov3: 'CODE 033 3. bead',
  naslov4: 'CODE 034 4. bead',
  naslov5: 'CODE 035 5. bead',
  naslov6: 'CODE 036 Memoare',
  naslov7: 'CODE 037 1. bead',
  naslov8: 'CODE 038 2. bead',
  naslov9: 'CODE 039 3.  bead',
  naslov10: 'CODE 040 4.  bead',
  naslov11: 'CODE 041 5.  bead',
  naslov12: 'CODE 042 6.  bead',
  naslov13: 'CODE 043 7.  bead',
  naslov14: 'CODE 044 8.  bead',
  naslov15: 'CODE 045 9.  bead',
  naslov16: 'CODE 046 10.  bead',
  naslov17: 'CODE 047 Second decade',
  naslov18: 'CODE 048 1.  bead',
  naslov19: 'CODE 049 2.  bead',
  naslov20: 'CODE 050 3.  bead',
  naslov21: 'CODE 051 4.  bead',
  naslov22: 'CODE 052 5.  bead',
  naslov23: 'CODE 053 6.  bead',
  naslov24: 'CODE 054 7.  bead',
  naslov25: 'CODE 055 8.  bead',
  naslov26: 'CODE 056 9.  bead',
  naslov27: 'CODE 057 10.  bead',
  naslov28: 'CODE 058 Third decade',
  naslov29: 'CODE 059 1.  bead',
  naslov30: 'CODE 060 2.  bead',
  naslov31: 'CODE 061 3.  bead',
  naslov32: 'CODE 062 4.  bead',
  naslov33: 'CODE 063 5.  bead',
  naslov34: 'CODE 064 6.  bead',
  naslov35: 'CODE 065 7.  bead',
  naslov36: 'CODE 066 8.  bead',
  naslov37: 'CODE 067 9.  bead',
  naslov38: 'CODE 068 10.  bead',
  naslov39: 'CODE 069 Fourth decade',
  naslov40: 'CODE 070 1.  bead',
  naslov41: 'CODE 071 2.  bead',
  naslov42: 'CODE 072 3.  bead',
  naslov43: 'CODE 073 4.  bead',
  naslov44: 'CODE 074 5.  bead',
  naslov45: 'CODE 075 6.  bead',
  naslov46: 'CODE 076 7.  bead',
  naslov47: 'CODE 077 8.  bead',
  naslov48: 'CODE 078 9.  bead',
  naslov49: 'CODE 079 10.  bead',
  naslov50: 'CODE 080 Fifth decade',
  naslov51: 'CODE 081 1.  bead',
  naslov52: 'CODE 082 2.  bead',
  naslov53: 'CODE 083 3.  bead',
  naslov54: 'CODE 084 4.  bead',
  naslov55: 'CODE 085 5.  bead',
  naslov56: 'CODE 086 6.  bead',
  naslov57: 'CODE 087 7.  bead',
  naslov58: 'CODE 088 8.  bead',
  naslov59: 'CODE 089 9.  bead',
  naslov60: 'CODE 090 10.  bead',
  naslov61: 'CODE 091 The end of the rosary',
  prvaDeseticaZalosna: 'CODE 092 Isus, koji se za nas krvlju znojio!',
  prvaDeseticaSlavna: 'CODE 093 Isus, koji je od mrtvih uskrsnuo!',
  prvaDeseticaRadosna: 'CODE 094 Isus, koga si Djevice, po Duhu Svetom začela!',
  prvaDeseticaSvjetla: 'CODE 095 Isus, koji je na rijeci Jordanu kršten bio!',
  drugaDeseticaZalosna: 'CODE 096 Isus, koji je za nas bičevan bio!',
  drugaDeseticaSlavna: 'CODE 097 Isus, koji je na nebo uzašao!',
  drugaDeseticaRadosna:
    'CODE 098 Isus, koga si Djevice, Elizabeti u pohode nosila!',
  drugaDeseticaSvjetla: 'CODE 099 Isus, koji nam se na svadbi u Kani objavio!',
  trecaDeseticaZalosna: 'CODE 100 Isus, koji je za nas trnjem okrunjen bio!',
  trecaDeseticaSlavna: 'CODE 101 Isus, koji je Duha Svetog poslao!',
  trecaDeseticaRadosna: 'CODE 102 Isus, koga si Djevice, rodila!',
  trecaDeseticaSvjetla:
    'CODE 103 Isus, koji nam je Kraljevstvo Božje navijestio i na obraćenje pozvao!',
  cetvrtaDeseticaZalosna: 'CODE 104 Isus, koji je za nas teški križ nosio!',
  cetvrtaDeseticaSlavna: 'CODE 105 Isus, koji je Tebe Djevice, na nebo uzeo!',
  cetvrtaDeseticaRadosna: 'CODE 106 Isus, koga si Djevice, u hramu prikazala!',
  cetvrtaDeseticaSvjetla:
    'CODE 107 Isus, koji se na gori preobrazio i svoju nam slavu objavio!',
  petaDeseticaZalosna: 'CODE 108 Isus, koji je za nas raspet bio!',
  petaDeseticaSlavna: 'CODE 109 Isus, koji je Tebe Djevice, na nebu okrunio!',
  petaDeseticaRadosna: 'CODE 110 Isus, koga si Djevice, u hramu našla!',
  petaDeseticaSvjetla:
    'CODE 111 Isus, koji nam se u otajstvu euharistije darovao!',
};
