export const english = {
  id: 'en',
  jezik: 'English',
  naslovna01: 'Hail Mary and Jesus!',
  naslovna02: 'The Holy Rosary',
  daniTjedan: [
    { value: 'pon', label: 'Monday' },
    { value: 'uto', label: 'Tuesday' },
    { value: 'sri', label: 'Wednesday' },
    { value: 'cet', label: 'Thursday' },
    { value: 'pet', label: 'Friday' },
    { value: 'sub', label: 'Saturday' },
    { value: 'ned', label: 'Sunday' },
  ],
  otajstvoSvjetla: 'The Luminous Mysteries',
  otajstvoZalosno: 'The Sorrowful Mysteries',
  otajstvoSlavno: 'The Glorious Mysteries',
  otajstvoRadosna: 'The Joyful Mysteries',
  uImeOcaSina:
    'In the name of the Father and of the Son † and of the Holy Spirit.',
  oceNas: 'Our father',
  isuse: 'Jesus',
  oceNasText:
    ', who art in heaven, hallowed be thy name, thy kingdom come, thy will be done on earth as it is in heaven. Give us this day our daily bread and forgive us our trespasses as we forgive those who trespass against us, and lead us not into temptation but deliver us from evil.',
  amen: 'Amen.',
  slavaOcu:
    'Glory be to the Father, and to the Son, † and to the Holy Spirit. As it was in the beginning, is now, and ever shall be, world without end.',
  zdravoMarijaPocetak:
    'Hail, Mary, full of grace, the Lord is with thee: blessed art thou among women, and blessed is the fruit of thy womb,',
  zdravoMarijaKraj:
    'Holy Mary, Mother of God, pray for us sinners, now, and at the hour of our death.',
  oMojIsuse:
    'O my Jesus, forgive us our sins, save us from the fires of hell, lead all souls to heaven especially those who are in most need of Your mercy.',
  zdravoKraljice:
    'Hail, holy Queen, mother of mercy; hail, our life, our sweetness and our hope. To thee do we cry, poor banished children of Eve; to thee do we send up our sighs, mourning and weeping in this valley of tears. \n Turn then, most gracious advocate, thine eyes of mercy towards us; and after this our exile, show unto us the blessed fruit of thy womb, Jesus.  \n O clement, O loving, O sweet Virgin Mary.',
  apostolskoUvod:
    ', it is for Your love, for the conversion of sinners, and in reparation for the sins done against the Immaculate Heart of Mary.',
  apostolskoVjerovanje: "The Apostles' Creed",
  apostolskoVjerovanjeText:
    "I believe in God, the Father almighty, creator of heaven and earth. I believe in Jesus Christ, God's only Son, our Lord, who was conceived by the Holy Spirit, born of the virgin Mary, suffered under Pontius Pilate, was crucified, died and was buried; he descended to the dead. On the third day he rose again; he ascended into heaven, he is seated at the right hand of the Father, and he will come to judge the living and the dead. I believe in the Holy Spirit, the holy Christian Church, the communion of saints, the forgiveness of sins, the resurrection of the body, and the life everlasting.",
  vjera: 'Jesus, which may increase our faith!',
  ufanje: 'Jesus, which may increase our hope!',
  ljubav: 'Jesus, which may increase our love!',
  naslov0: 'Holy Mary rosary †',
  naslov1: '1. bead',
  naslov2: '2. bead',
  naslov3: '3. bead',
  naslov4: '4. bead',
  naslov5: '5. bead',
  naslov6: 'Memoare',
  naslov7: '1. bead',
  naslov8: '2. bead',
  naslov9: '3. bead',
  naslov10: '4. bead',
  naslov11: '5. bead',
  naslov12: '6. bead',
  naslov13: '7. bead',
  naslov14: '8. bead',
  naslov15: '9. bead',
  naslov16: '10. bead ',
  naslov17: 'Second decade',
  naslov18: '1. bead',
  naslov19: '2. bead',
  naslov20: '3. bead',
  naslov21: '4. bead',
  naslov22: '5. bead',
  naslov23: '6. bead',
  naslov24: '7. bead',
  naslov25: '8. bead',
  naslov26: '9. bead',
  naslov27: '10. bead second decade',
  naslov28: 'Third decade',
  naslov29: '1. bead',
  naslov30: '2. bead',
  naslov31: '3. bead',
  naslov32: '4. bead',
  naslov33: '5. bead',
  naslov34: '6. bead',
  naslov35: '7. bead',
  naslov36: '8. bead',
  naslov37: '9. bead',
  naslov38: '10. bead',
  naslov39: 'Fourth decade',
  naslov40: '1. bead',
  naslov41: '2. bead',
  naslov42: '3. bead',
  naslov43: '4. bead',
  naslov44: '5. bead',
  naslov45: '6. bead',
  naslov46: '7. bead',
  naslov47: '8. bead',
  naslov48: '9. bead',
  naslov49: '10. bead',
  naslov50: 'Fifth decade',
  naslov51: '1. bead',
  naslov52: '2. bead',
  naslov53: '3. bead',
  naslov54: '4. bead',
  naslov55: '5. bead',
  naslov56: '6. bead',
  naslov57: '7. bead',
  naslov58: '8. bead',
  naslov59: '9. bead',
  naslov60: '10. bead',
  naslov61: 'The end of the rosary',
  prvaDeseticaZalosna: 'Jesus, who was sweating blood for us!',
  prvaDeseticaSlavna: 'Jesus, who rose from the dead!',
  prvaDeseticaRadosna:
    'Jesus, whom you, the Virgin, by the Holy Spirit conceived!',
  prvaDeseticaSvjetla: 'Jesus, who was baptized on the Jordan River!',
  drugaDeseticaZalosna: 'Jesus ,which was whipped for us!',
  drugaDeseticaSlavna: 'Jesus, who ascended to heaven!',
  drugaDeseticaRadosna: 'Jesus, whom you, the Virgin, Elizabeth went to visit!',
  drugaDeseticaSvjetla:
    'Jesus, which was revealed to us at the wedding in Cana!',
  trecaDeseticaZalosna: 'Jesus, who for us was crowned with thorns!',
  trecaDeseticaSlavna: 'Jesus, who sent the Holy Spirit!',
  trecaDeseticaRadosna: 'Jesus, whom you, Virgin, gave birth to!',
  trecaDeseticaSvjetla:
    'Jesus, who proclaimed to us the Kingdom of God and called us to conversion!',
  cetvrtaDeseticaZalosna: 'Jesus, who carried for us a heavy cross!',
  cetvrtaDeseticaSlavna: 'Jesus, who took Thee, O Virgin, to heaven!',
  cetvrtaDeseticaRadosna: 'Jesus, whom you have shown, Virgin, in the temple!',
  cetvrtaDeseticaSvjetla:
    'Jesus, who was transformed on the mountain and revealed his glory to us!',
  petaDeseticaZalosna: 'Jesus, who was crucified for us!',
  petaDeseticaSlavna: 'Jesus, who crowned Thee Virgin, in heaven!',
  petaDeseticaRadosna: 'Jesus, whom you found, Virgin, in the temple!',
  petaDeseticaSvjetla:
    'Jesus, who was given to us in the mystery of the Eucharist!',
};
